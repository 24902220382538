import Swiper, {Autoplay, Pagination} from 'swiper';

export function manipulateSlideshow3 (query = document.querySelectorAll('.swiper-initialized.slideshow--3')) {
    for (let slideshow of query) {
        let slideshowSwiper = slideshow.swiper;
        slideshowSwiper.destroy(false, true);
        Swiper.use([Autoplay, Pagination]);
        let slideshow3New = new Swiper('.slideshow--3', {
            autoplay: false,
            effect: "slide",
            slidesPerView: 2,
            centeredSlides: true,
            loop: true,
            initialSlide: 2,
            breakpoints: {
                1280: {
                    initialSlide: 0,
                    centeredSlides: false,
                    slidesPerView: 4,
                },
                960: {
                    initialSlide: 0,
                    centeredSlides: false,
                    slidesPerView: 3
                },
                767: {
                    initialSlide: 0,
                    centeredSlides: false
                }
            }
        });

        if(window.outerWidth < 767) {
            const slides = slideshow.querySelectorAll('.swiper-slide');

            let slideLength = slides.length;
            for(let i = slideLength - 1; i >= 0; i--) {
                let slide = slides[i];
                const clonedSlide = slide.cloneNode(true);
                clonedSlide.classList.add('swiper-slide-duplicated');
                clonedSlide.dataset.swiperSlideIndex = parseInt(clonedSlide.dataset.swiperSlideIndex) + slideLength;
                slideshow.querySelector('.swiper-wrapper').prepend(clonedSlide);
            }
        }

    }
}