
export function initLoadPdfInModal(wrapper = document){
    for(let linkInModal of wrapper.querySelectorAll(".js-openLinkInModal")){
        linkInModal.addEventListener("click",function (e){
            e.preventDefault();
            setTimeout(function (){
                const pdfUrl = linkInModal.href;
                if (pdfUrl && pdfUrl.toLowerCase().endsWith('.pdf')) {
                    const newModals = document.querySelectorAll("#newModal");
                    if(newModals){
                        const newModal = newModals[newModals.length-1];

                        if(newModal){
                            const container = newModal.querySelector('.modal__body');

                            if(container){
                                container.innerHTML = `<div class='pdfViewer'><embed src="${pdfUrl}" width="100%" type="application/pdf"></div>`;
                            }
                        }
                    }
                }
            },200);
        });
    }
}