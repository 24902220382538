import Swiper, {Autoplay, Navigation} from 'swiper';

export function testimonialsSlider (query = document.querySelectorAll('.testimonialsSwiper')) {
    for (let slideshow of query) {
        Swiper.use([Autoplay, Navigation]);
        new Swiper(slideshow, {
            loop: true,
            autoplay: true,
            slidesPerView: 1,
            spaceBetween: 22,
            navigation: {
                nextEl: '.swiper-button-next-testimonials',
                prevEl: '.swiper-button-prev-testimonials',
            },
            breakpoints: {
                // when window width is >= 320px
                601: {
                    slidesPerView: 2
                },
                1201: {
                    slidesPerView: 3
                }
            }
        });
    }
}