export function manipulateSlideshow1 (query = document.querySelectorAll('.swiper-initialized.slideshow--1')) {
    for (let slideshow of query) {
        var slideshowSwiper = slideshow.swiper;
        slideshowSwiper.params.slidesPerView = 4;
        slideshowSwiper.params.speed = 300;

        if(window.screen.width < 1250 && window.screen.width > 860) {
            slideshowSwiper.params.slidesPerView = 3;
            slideshowSwiper.params.spaceBetween = 20;
        }

        if(window.screen.width < 860 && window.screen.width > 540) {
            slideshowSwiper.params.slidesPerView = 2;
            slideshowSwiper.params.spaceBetween = 20;
        }

        if(window.screen.width < 540) {
            slideshowSwiper.params.slidesPerView = 1;
            slideshowSwiper.params.spaceBetween = 20;
        }

        slideshow.classList.add("swiper-initialized-finished");

        //slideshowSwiper.params.autoplay = false;
    }
}